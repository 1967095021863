import queryStringSerializer from '@shared/queryStringSerializer';

const apiAdminRoutes = {
  building: buildingId => `/api/admin/buildings/${buildingId}`,
  buildingMarketingMedia: buildingId => `/api/admin/buildings/${buildingId}/marketing_media`,
  buildingDemographics: buildingId => `/api/admin/buildings/${buildingId}/demographics`,
  buildingLink: '/api/admin/buildings/link',
  spacesRelinkPreview: '/api/admin/spaces/preview',
  spaceRelink: '/api/admin/spaces/link',
  buildingListings: (buildingId, page = 1, query = '', statuses = []) => {
    const params = {
      query,
      page,
      statuses,
    };
    const queryString = queryStringSerializer(params);

    return `/api/admin/buildings/${buildingId}/listings?${queryString}`;
  },
  buildingPhotosZipDownload: (buildingId, photoIds) =>
    `/api/admin/buildings/${buildingId}/images_zip_urls?photo_ids=${photoIds}`,
  buildingPhotosArchiveToggle: buildingId => `/api/admin/buildings/${buildingId}/photos/archive`,
  buildingPhotosOrder: buildingId => `/api/admin/buildings/${buildingId}/photos/order`,
  buildingPhoto: (buildingId, photoId) => `/api/admin/buildings/${buildingId}/photos/${photoId}`,
  buildingPhotosCreate: buildingId => `/api/admin/buildings/${buildingId}/photos`,
  buildingPhotoDelete: (buildingId, photoId) =>
    `/api/admin/buildings/${buildingId}/photos/${photoId}`,
  buildingVideo: (buildingId, videoId) => `/api/admin/buildings/${buildingId}/videos/${videoId}`,
  buildingVideoCreate: buildingId => `/api/admin/buildings/${buildingId}/videos`,
  buildingVideoDelete: (buildingId, videoId) =>
    `/api/admin/buildings/${buildingId}/videos/${videoId}`,
  buildingVideoUpdate: (buildingId, videoId) =>
    `/api/admin/buildings/${buildingId}/videos/${videoId}`,
  buildingAmenitySet: buildingId => `/api/admin/buildings/${buildingId}/amenity_set`,
  buildingSearch: ({
    query = '',
    page = 1,
    spaceProviders = [],
    statuses = [],
    cities = [],
    states = [],
    countries = [],
    latitude = null,
    longitude = null,
    distance = null,
  }: $TSFixMe) => {
    const params = {
      query,
      page,
      space_providers: spaceProviders,
      statuses,
      cities,
      states,
      latitude,
      longitude,
      distance,
      countries,
    };

    const queryString = queryStringSerializer(params);

    return `/api/admin/buildings?${queryString}`;
  },
  buildingFilters: '/api/admin/filters/buildings',
  buildingUnlistedSpaces: (buildingId, page = 1, query) =>
    `/api/admin/buildings/${buildingId}/unlisted_spaces?query=${query}&page=${page}`,
  buildings: '/api/admin/buildings',
  buildingIndustrialAttributes: (id: string) => `/api/admin/buildings/${id}/industrial_attributes`,
  buildingMicrosite: (id: string) => `/api/admin/buildings/${id}/microsite`,
  buildingMicrositePreview: (id: string) => `/api/admin/buildings/${id}/microsite_preview`,
  companiesPlain: '/api/admin/companies',
  companies: (query: string | undefined = undefined, page: number = 1) => {
    const queryString = queryStringSerializer({ query, page });
    return `/api/admin/companies?${queryString}`;
  },
  demographicDistance: '/api/admin/demographic_distance',
  fiberProviders: (query, page = 1) => {
    const queryString = queryStringSerializer({ query, page });
    return `/api/admin/fiber_providers?${queryString}`;
  },
  company: id => `/api/admin/companies/${id}`,
  vtsProperty: id => `/api/admin/vts_properties/${id}`,
  vtsSpace: vtsSpaceId => `/api/admin/vts_spaces/${vtsSpaceId}`,
  floorPlanCreate: '/api/admin/floor_plans',
  floorPlanUpdate: floorPlanId => `/api/admin/floor_plans/${floorPlanId}`,
  floorPlanDelete: floorPlanId => `/api/admin/floor_plans/${floorPlanId}`,
  listing: listingId => `/api/admin/listings/${listingId}`,
  listings: '/api/admin/listings',
  listingPhoto: (listingId, photoId) => `/api/admin/listings/${listingId}/photos/${photoId}`,
  listingPhotosOrder: listingId => `/api/admin/listings/${listingId}/photos/order`,
  listingPhotosCreate: listingId => `/api/admin/listings/${listingId}/photos`,
  listingPhotoDelete: (listingId, photoId) => `/api/admin/listings/${listingId}/photos/${photoId}`,
  listingPhotosArchiveToggle: listingId => `/api/admin/listings/${listingId}/photos/archive`,
  listingPhotosZipDownload: (listingId, photoIds) =>
    `/api/admin/listings/${listingId}/images_zip_urls?photo_ids=${photoIds}`,
  listingPrices: listingId => `/api/admin/listings/${listingId}/prices`,
  listingAmenitySet: listingId => `/api/admin/listings/${listingId}/amenity_set`,
  listingVideo: (listingId, videoId) => `/api/admin/listings/${listingId}/videos/${videoId}`,
  listingVideoCreate: listingId => `/api/admin/listings/${listingId}/videos`,
  listingVideoDelete: (listingId, videoId) => `/api/admin/listings/${listingId}/videos/${videoId}`,
  listingVideoUpdate: (listingId, videoId) => `/api/admin/listings/${listingId}/videos/${videoId}`,
  listingRoomsCreate: listingId => `/api/admin/listings/${listingId}/rooms`,
  listingRoomsUpdate: (listingId, id) => `/api/admin/listings/${listingId}/rooms/${id}`,
  listingRoomsDelete: (listingId, id) => `/api/admin/listings/${listingId}/rooms/${id}`,
  listingSearch: ({
    query = '',
    sortBy = null,
    page = 1,
    spaceProviders = [],
    statuses = [] as string[],
    cities = [],
    states = [],
    countries = [],
  }) => {
    const params = {
      query,
      sort_by: sortBy,
      page,
      space_providers: spaceProviders,
      statuses,
      cities,
      states,
      countries,
    };

    const queryString = queryStringSerializer(params);

    return `/api/admin/listings?${queryString}`;
  },
  listingFilters: '/api/admin/filters/listings',
  properties: id => `/api/admin/properties/${id}`,
  user: userId => `/api/admin/users/${userId}`,
  userFilters: '/api/admin/filters/users',
  userSearch: (query: string, page: number = 1, roles: string[] = []) => {
    const params = {
      query,
      page,
      roles,
    };
    const queryString = queryStringSerializer(params);

    return `/api/admin/users?${queryString}`;
  },
  users: '/api/admin/users',
  landlord: landlordId => `/api/admin/landlords/${landlordId}`,
  landlords: '/api/admin/landlords',
  landlordsList: (query: string | undefined = undefined, page: number = 1) => {
    const queryString = queryStringSerializer({ query, page });
    return `/api/admin/landlords?${queryString}`;
  },
  landlordPhoto: (landlordId, photoId) => `/api/admin/landlords/${landlordId}/photos/${photoId}`,
  landlordPhotoOrder: landlordId => `/api/admin/landlords/${landlordId}/photos/order`,
  landlordPhotoCreate: landlordId => `/api/admin/landlords/${landlordId}/photos`,
  landlordPhotoDelete: ({ photoId, landlordId }) =>
    `/api/admin/landlords/${landlordId}/photos/${photoId}`,
  landlordPhotoUpdate: ({ photoId, landlordId }: { photoId: string; landlordId: string }) =>
    `/api/admin/landlords/${landlordId}/photos/${photoId}`,
  landlordPhotosZipDownload: (landlordId, photoIds) =>
    `/api/admin/landlords/${landlordId}/images_zip_urls?photo_ids=${photoIds}`,
  landlordPhotosArchiveToggle: landlordId => `/api/admin/landlords/${landlordId}/photos/archive`,
  landlordVideo: (landlordId, videoId) => `api/admin/landlords/${landlordId}/videos/${videoId}`,
  landlordVideoCreate: landlordId => `/api/admin/landlords/${landlordId}/videos`,
  landlordVideoUpdate: ({ videoId, landlordId }) =>
    `/api/admin/landlords/${landlordId}/videos/${videoId}`,
  landlordVideoDelete: ({ videoId, landlordId }) =>
    `/api/admin/landlords/${landlordId}/videos/${videoId}`,
  websitePreviewLink: (landlordId, buildingSlug) =>
    `/api/admin/landlord/${landlordId}/buildings/${encodeURIComponent(buildingSlug)}/web/links`,
  micrositePreviewLink: (landlordId, buildingSlug) =>
    `/api/admin/landlord/${landlordId}/buildings/${encodeURIComponent(
      buildingSlug,
    )}/microsite/link`,
  appendPrependLink: (landlordId, buildingSlug) =>
    `/api/admin/landlord/${landlordId}/buildings/${encodeURIComponent(
      buildingSlug,
    )}/brochure_attrs`,
} as const;

export default apiAdminRoutes;
